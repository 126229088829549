<template>
  <div>
    <b-card header="电阻分压计算" header-bg-variant="tmprimary" header-text-variant="white">
      <b-row>
        <b-col cols="3">
          <b-img src="@/assets/calc/basic_voltdiv/resdiv.svg" />
        </b-col>
        <b-col>
          <b-form inline>
            <b-input-group append="V">
              <b-input-group-prepend is-text>
                输入电压 U<sub>In</sub>=
              </b-input-group-prepend>
              <b-form-input id="input_g1_uin" v-model="input_g1_uin" type="text" />
            </b-input-group>
          </b-form>
          <br />
          <b-form inline="">
            <b-input-group>
              <b-input-group-prepend is-text>
                上端电阻 R<sub>1</sub>
              </b-input-group-prepend>
              <b-form-input id="input_g1_r1val" v-model="input_g1_r1val" type="text" />
              <b-input-group-append>
                <b-form-select id="input_g1_r1unit" v-model="input_g1_r1unit">
                  <option value="1" selected>Ω</option>
                  <option value="1000">kΩ</option>
                  <option value="1000000">MΩ</option>
                  <option value="1000000000">GΩ</option>
                </b-form-select>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <br />
          <b-form inline="">
            <b-input-group>
              <b-input-group-prepend is-text>
                下端电阻 R<sub>2</sub>
              </b-input-group-prepend>
              <b-form-input id="input_g1_r2val" v-model="input_g1_r2val" type="text" />
              <b-input-group-append>
                <b-form-select id="input_g1_r2unit" v-model="input_g1_r2unit">
                  <option value="1" selected>Ω</option>
                  <option value="1000">kΩ</option>
                  <option value="1000000">MΩ</option>
                  <option value="1000000000">GΩ</option>
                </b-form-select>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <br />
          <b-form inline>
            <b-input-group append="V">
              <b-input-group-prepend is-text>
                输出电压 U<sub>Output</sub>=
              </b-input-group-prepend>
              <b-form-input id="output_g1_uout" v-model="output_g1_uout" type="text" readonly />
            </b-input-group>
          </b-form>
          <br />
          <b-form inline>
            <b-input-group>
              <b-input-group-prepend is-text>
                电阻上的电流 I<sub>1</sub>=
              </b-input-group-prepend>
              <b-form-input id="output_g1_i" v-model="output_g1_i" type="text" readonly />
              <b-input-group-append>
                <b-form-select id="input_g1_iunit" v-model="input_g1_iunit">
                  <option value="0.001">kA</option>
                  <option value="1" selected>A</option>
                  <option value="1000">mA</option>
                  <option value="1000000">μA</option>
                  <option value="1000000000">nA</option>
                </b-form-select>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input_g1_uin: "0",
      input_g1_r1val: "0",
      input_g1_r1unit: "1",
      input_g1_r2val: "0",
      input_g1_r2unit: "1",
      input_g1_iunit: "1"
    };
  },
  computed: {
    output_g1_uout() {
      var ar1 = Number(this.input_g1_r1val) * Number(this.input_g1_r1unit);
      var ar2 = Number(this.input_g1_r2val) * Number(this.input_g1_r2unit);
      var art = ar1 + ar2;
      return (Number(this.input_g1_uin) * ar2) / art;
    },
    output_g1_i() {
      var au = Number(this.input_g1_uin);
      var ar1 = Number(this.input_g1_r1val) * Number(this.input_g1_r1unit);
      var ar2 = Number(this.input_g1_r2val) * Number(this.input_g1_r2unit);
      var art = ar1 + ar2;
      var ai = au / art;
      return ai * Number(this.input_g1_iunit);
    }
  }
};
</script>