<template>
  <div>
    <VD001 />
  </div>
</template>
<script>
import VD001 from "@/views/submods/basic_voltdiv/vd001.vue"
export default {
  components: { VD001 }
}
</script>